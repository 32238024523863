section.carousel-block {
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;

	.sign-in-block {
		background-color: $light-grey;
		display: none;
		max-height: 60.4rem;
		padding: 3rem 3.2rem;

		&-title {
			margin-bottom: 1.6rem;
			color: $middle-grey;
		}

		&-input {
			margin-bottom: 2.5rem;
		}

		&-links {
			align-items: center;
			display: flex;
			font-size: 1.6rem;
			margin: 2.4rem 0 4.3rem;

			a {
				color: $navy;
				text-decoration: underline;
			}

			span {
				margin: 0 1.8rem;
			}
		}

		.btn-register {
			margin-bottom: 4rem;
		}

		.sign-in-policyholders {
			padding-bottom: 2.3rem;
			border-bottom: 1px solid $border-color;
		}

		.sign-in-agents {
			padding-top: 2.3rem;
		}
	}
}

.mb-2 {
	margin-bottom: 2rem;
}

.hero-carousel {
    min-width: 0;

    picture {
        display: block;
        overflow: hidden;
    }

    img {
        display: block;
        height: 23rem;
        object-fit: cover;
        object-position: center;
        width: 100%;
    }

    .carousel-slide {      

        .theme-light {
            color: $dark-grey;

            .btn-outline {
                color: $navy;
                border-color: $navy;

                &:hover {
                    background-color: $navy;
                    color: white;
                }
            }
        }

        &.theme-dark {
            background-color: $navy;
            color: white;
        }
    }

    .carousel-slide-content {
        padding: 1.4rem 1.7rem;

        .carousel-slide-title {
            margin: 1.4rem auto 0.5rem;
        }

        .carousel-slide-desc {
            font-weight: $font-weight-normal;
            margin: 0 auto 0.5rem;
        }

        .carousel-slide-eyebrow {
            font-size: 1.8rem;
            text-transform: uppercase;
            margin-bottom: 2.4rem;
        }
    }
}

.carousel {
	.slick-list {
		height: 100%;
	}

	.slick-track {
		display: flex !important;
		height: 100%;
		width: 100% !important;
	}
	
	.slick-slide {
		height: auto !important;
		flex-shrink: 0;

		& > div {
			height: 100%;
		}
	}

	.carousel-slide {
		position: relative;
		height: 100%;

		&-no-bg {
			display: flex !important;

			.carousel-slide-content {
				align-self: center;
			}
		}
	}

	.slick-arrow {
		background: transparent;
        border: 1px solid transparent;
        border-radius: 50%;
        color: white;
        display: none;
        font-size: 0;
        height: 3rem;
        position: absolute;
        top: 50%;
		transform: translateY(-50%);
        width: 3rem;
        z-index: 1;

		&::before {
			font-family: $font-awesome;
			font-size: 3rem;
			font-weight: 900;
			color: white;
			margin: 0;
			-webkit-text-stroke-width: 1px;
			-webkit-text-stroke-color: #002e4e;
		}

		&:hover {
			cursor: pointer;
		}

		&.slick-next {
			right: 1.6rem;

            &::before {
				content: "\f054";
            }
		}

		&.slick-prev {
            left: 1.6rem;

            &::before {
                content: "\f053";
            }
        }
    }
	
	.slick-dots {
		align-items: center;
		display: flex;
		justify-content: center;
		list-style: none;
		margin: 2.2rem auto;
		padding-left: 0;
		width: 100%;

		li {
			align-items: center;
			display: flex;

			&::marker {
				color: #88837c;
			}

			&.slick-active {
				button {
					background: #88837c;
					height: 1.6rem;
					width: 1.6rem;
				}
			}

			button {
				background: transparent;
				border: 2px solid #88837c;
				border-radius: 50%;
				box-shadow: none;
				color: transparent;
				height: 1.2rem;
				margin: 0 1rem;
				padding: 0;
				width: 1.2rem;
			}
		}
	}
}

.theme-pmic {
	section.carousel-block {
		.sign-in-block {
			display: flex;
			flex-direction: column;

			.sign-in-policyholders {
				padding-bottom: 2.3rem;
			}

			.sign-in-agents {
				align-self: auto;
			}
		}
	}
}

.theme-inservco {
    .hero-carousel {
        .carousel-slide {
            &.nobackground-color {
                background: transparent !important;

                img {
                    opacity: 0.46;
                }
            }
        }

        picture {
            box-shadow: inset 0 0 0 100vmax rgb(0 0 0);
        }

        img {
            height: auto;
            min-height: 23em;
            opacity: 0.46;
        }

        .carousel-slide-content {
            left: 50%;
            margin: 0 auto;
            max-width: 100%;
            padding: 1.4rem 1.7rem;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 100%;

            .desktop__only {
                display: none;
            }

            .mobile__only {
                display: block;
            }

            &.desktop__only {
                display: none;
            }

            &.mobile__only {
                display: block;
            }

            &.text-left, &.text-right, &.text-center {
                text-align: center;
            }

            &.text-left {
                .btn-block-wrapper {
                    justify-content: center;
                }
            }

            &.text-right {
                .btn-block-wrapper {
                    justify-content: center;
                }
            }
        }
    }

    .carousel {
        .slick-dots {
            position: absolute;
            bottom: -2%;

            li button {
                background: white;
                color: white;
                border: 2px solid #fff;
            }
        }
    }
}

